$border-radius: 1px;
$primary: #ff0000;
$secondary: #336699;
$info: #336699;
$success: #6cdc6c;
$warning: #ffe15b;
$error: #e3470e;
$body-text: #000000;
$body-bg: #ffffff;
$headings-text: #141414;
$subtle-text: #6c757d;
$disabled-text: #8f8f8f;
$component-color: #1c3a4c;
$component-bg: #ffffff;
$base-text: #1c3a4c;
$base-bg: #ffffff;
$hovered-text: #16181b;
$hovered-bg: #d0daff;
$selected-text: #ffffff;
$selected-bg: #336699;
$button-text: #313131;
$button-bg: #ffffff;
$link-text: #336699;
$link-hover-text: #e65317;
$series-a: #ff7943;
$series-b: #e84d0e;
$series-c: #40a9e3;
$series-d: #016a98;
$series-e: #7a8c95;
$series-f: #355261;

@import "~@progress/kendo-theme-default/dist/all.scss";